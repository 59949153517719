import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import GettingTattooed from "../components/GettingTattooed"

const GettingTattooedPage = () => {
  return (
    <Layout>
      <SEO title="Getting Tattooed" />
      <GettingTattooed />
    </Layout>
  )
}

export default GettingTattooedPage
