import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import media from "../../styles/media"

const Container = styled.div`
  padding: var(--gutter-m) var(--gutter-s) var(--gutter-l) var(--gutter-s);
  ${media.tablet`
    padding: var(--gutter-m) var(--gutter-l) var(--gutter-l) var(--gutter-l);
  `}
`

const Header = styled.div`
  margin-bottom: var(--gutter-m);
  h1 {
    font-size: 3rem;
    line-height: 1;
    transform: translateY(30%);
    position: relative;
    z-index: 2;
  }
  ${media.tablet`
    margin-bottom: var(--gutter-l);
    h1 {
      font-size: 5.5rem;
    }
  `}
`

const Body = styled.div`
  .block {
    margin-bottom: var(--gutter-m);
    h4,
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    h4 {
    }
    p {
    }
    margin-bottom: 1.5rem;
  }
  ${media.tablet`
    max-width: 65vw;
  `}
`

const GettingTattooed = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "getting-tattooed/hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <Header>
        <h1 className="font-black">getting tattooed</h1>
        <Img
          fluid={data.heroImage.childImageSharp.fluid}
          className="hero-wrapper"
        />
      </Header>
      <Body>
        <div className="block">
          <h4 className="font-black">Can I get tattooed at the Convention?</h4>
          <p>
            Yes you can, that’s what it’s all about! We encourage you to browse
            the work of the 100 invited tattooers who offer a massive variety of
            styles.
          </p>
          <p>
            Head to our Instagram or Artists page on the website to see them
            all.
          </p>
        </div>
        <div className="block">
          <h4 className="font-black">Do I need to book my tattoo?</h4>
          <p>
            Conventions have a WALK-UP culture. You just walk up to an artist on
            the spot. Most of the tattooers will have space to tattoo you.
            However, if you have your heart dead set and want to be sure your
            favorite artists will have time, you can pre- book by contacting
            them directly via our website Artist page. The best time to pre-book
            is from about a month before the event.
          </p>
          <p>
            If you don’t get a response (as the tattooers are often traveling)
            we encourage you to buy a weekend pass, show up as early as the
            doors open and chat to the artist, as they usually will make time
            for you at some point on the weekend. Plus, with 100 tattooers to
            choose from you will be spoilt for choice and may just fall in love
            with something you did not expect!
          </p>
        </div>
        <div className="block">
          <h4 className="font-black">Do I have to buy a ticket to get tattooed?</h4>
          <p>
            Yes, you do. Your convention ticket gives you access to the massive
            weekend we put together, meeting all the tattooers, watching 6 live
            bands, shopping at our outdoor goods market, a beer tent with 3
            different local food trucks, and incredible views from the deck
            (perfect for a cocktail or two). We also serve the best coffee &
            donuts!
          </p>
        </div>
        <div className="block">
          <h4 className="font-black">Does my ticket include a tattoo?</h4>
          <p>No.</p>
        </div>
        <div className="block">
          <h4 className="font-black">How do I budget for my tattoo?</h4>
          <p>
            The SAITC does not quote on any tattoos. This is between you and
            your artists of choice. Tattooers generally work on the same or
            similar hourly rate as your familiar and reputable, professional
            local tattoo shops (R800-R1200 ph). Please note the artist rate can
            range and this is for you to discuss with them directly. We do
            provide a safe ATM service on site for you to draw cash.
          </p>
        </div>
        <div className="block">
          <h4 className="font-black">How does getting tattooed work?</h4>
          <p>
            You choose a design, discuss it with your artist, book your spot.
            When it’s your alloted time, the artists will make and place the
            tattoo stencil, and after you’re happy with the placement, they will
            go ahead and start your tattoo.
          </p>
        </div>
        <div className="block">
          <h4 className="font-black">Does it hurt?</h4>
          <p>Yes it does a little, but it’ll be worth it.</p>
        </div>
        <div className="block">
          <h4 className="font-black">How do I care for my tattoo?</h4>
          <p>Please chat to your artist directly for any aftercare advise.</p>
        </div>
      </Body>
    </Container>
  )
}

export default GettingTattooed
